import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import List from "../List";
import ListTokenHeader from "../ListTokenHeader";
import Pagination from "../Pagination";

function Ribbits() {
  const [RibbitsHolders, setRibbitsHolders] = useState([]);
  const [floor, setFloor] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(25);
  const [lastPostIndex, setLastPostIndex] = useState(0);
  const [firstPostIndex, setFirstPostIndex] = useState(0);
  // eslint-disable-next-line
  const [pageLimit, setPagelimit] = useState(5);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [holders, setHolders] = useState(0);

  useEffect(() => {
    setLastPostIndex(currentPage * postsPerPage);
    setFirstPostIndex(lastPostIndex - postsPerPage);
    setCurrentPosts(RibbitsHolders.slice(firstPostIndex, lastPostIndex));
  }, [
    currentPage,
    postsPerPage,
    RibbitsHolders,
    firstPostIndex,
    lastPostIndex,
  ]);

  useEffect(() => {
    axios
      .get("https://richlist-back-end.onrender.com/floor")
      .then((res) => {
        setFloor(res.data.floorDataRes.RibbitsPrice);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [floor]);

  useEffect(() => {
    var holders = [];
    var number = 0;
    axios
      .get("https://richlist-back-end.onrender.com/Ribbits")
      .then((response) => {
        let ribbitsdata = response.data;
        ribbitsdata.forEach((account) => {
          let Ribbits = Number(account.Ribbits);
          if (Ribbits > 0) {
            number += 1;
          }
          let user = {
            XRPaddress: account.XRPaddress,
            Ribbits: Ribbits,
          };
          holders.push(user);
        });
        setRibbitsHolders(holders);
        setHolders(number);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  RibbitsHolders.sort((p1, p2) =>
    p1.Ribbits < p2.Ribbits ? 1 : p1.Ribbits > p2.Ribbits ? -1 : 0
  );

  let type = "Ribbits";
  let name = "RIBBITS";
  let accountName = "XRPaddress";
  let currency = "XRP";

  return (
    <div className="output">
      <ListTokenHeader
        holders={holders}
        floorPrice={floor}
        currency={currency}
      />
      <Pagination
        totalPosts={holders}
        holders={RibbitsHolders}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        maxPageLimit={maxPageLimit}
        setMaxPageLimit={setMaxPageLimit}
        minPageLimit={minPageLimit}
        setMinPageLimit={setMinPageLimit}
      />
      <List
        list={currentPosts}
        account={accountName}
        type={type}
        page={firstPostIndex}
        name={name}
      />
      <Pagination
        totalPosts={holders}
        holders={RibbitsHolders}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        maxPageLimit={maxPageLimit}
        setMaxPageLimit={setMaxPageLimit}
        minPageLimit={minPageLimit}
        setMinPageLimit={setMinPageLimit}
      />
    </div>
  );
}

export default Ribbits;
