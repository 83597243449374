import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import List from "../List";
import ListTokenHeader from "../ListTokenHeader";
import Pagination from "../Pagination";

function SRibbits() {
  const [sRibbitsHolders, setsRibbitsHolders] = useState([]);
  const [floor, setFloor] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(25);
  const [lastPostIndex, setLastPostIndex] = useState(0);
  const [firstPostIndex, setFirstPostIndex] = useState(0);
  // eslint-disable-next-line
  const [pageLimit, setPagelimit] = useState(5);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);

  useEffect(() => {
    setLastPostIndex(currentPage * postsPerPage);
    setFirstPostIndex(lastPostIndex - postsPerPage);
    setCurrentPosts(sRibbitsHolders.slice(firstPostIndex, lastPostIndex));
  }, [
    currentPage,
    postsPerPage,
    sRibbitsHolders,
    firstPostIndex,
    lastPostIndex,
  ]);

  useEffect(() => {
    axios
      .get("https://richlist-back-end.onrender.com/floor")
      .then((res) => {
        // console.log(res.data.floorDataRes.sRibbitsPrice);
        // let sNum = res.data.floorDataRes.sRibbitsPrice.slice(9, 15);
        let sRibbits = res.data.floorDataRes.sRibbitsPrice; // (1 / sNum).toFixed(7);
        console.log(isNaN(parseFloat(sRibbits)));
        if (isNaN(parseFloat(sRibbits)) === true) {
        } else {
          setFloor(sRibbits);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [floor]);

  useEffect(() => {
    axios
      .get("https://richlist-back-end.onrender.com/sRibbits")
      .then((response) => {
        var users = [];
        let data = response.data;
        for (const i in data) {
          let sRibbits = data[i].sRibbits;
          let user = {
            SGBaddress: data[i].SGBaddress,
            sRibbits: parseInt(sRibbits),
          };
          users.push(user);
        }
        setsRibbitsHolders(users);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  sRibbitsHolders.sort((p1, p2) =>
    p1.sRibbits < p2.sRibbits ? 1 : p1.sRibbits > p2.sRibbits ? -1 : 0
  );

  let name = "sRIBBITS";
  let type = "sRibbits";
  let accountName = "SGBaddress";
  let holders = sRibbitsHolders.length;
  let currency = "SGB";

  return (
    <div className="output">
      <ListTokenHeader
        holders={holders}
        floorPrice={floor}
        currency={currency}
      />
      <Pagination
        totalPosts={holders}
        holders={sRibbitsHolders}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        maxPageLimit={maxPageLimit}
        setMaxPageLimit={setMaxPageLimit}
        minPageLimit={minPageLimit}
        setMinPageLimit={setMinPageLimit}
      />
      <List
        list={currentPosts}
        account={accountName}
        type={type}
        page={firstPostIndex}
        name={name}
      />
      <Pagination
        totalPosts={holders}
        holders={sRibbitsHolders}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        maxPageLimit={maxPageLimit}
        setMaxPageLimit={setMaxPageLimit}
        minPageLimit={minPageLimit}
        setMinPageLimit={setMinPageLimit}
      />
    </div>
  );
}

export default SRibbits;
