import React from 'react'
import './App.css'
const Pagination = ({ 
  totalPosts,
  holders, 
  postsPerPage, 
  setPostsPerPage, 
  currentPage, 
  setCurrentPage, 
  pageLimit, 
  maxPageLimit, 
  setMaxPageLimit,
  minPageLimit,
  setMinPageLimit,
  setCurrentPosts }) => {


  const start = () => {
    setCurrentPage(1)
    setMaxPageLimit(pageLimit)
    setMinPageLimit(0)
    
  }

  const prev = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage-1)
    }
    let num = Math.ceil(pageLimit/2) 
    if (currentPage > (num) && currentPage < ((pagesLength - num)+1)) {
      setMaxPageLimit(((currentPage + pageLimit) - num)-1)
      setMinPageLimit((currentPage - num)-1)
  }
  }

  const prevDots = () => {
    if (currentPage < (pageLimit+1)) {
      setCurrentPage(1)
      setMaxPageLimit(pageLimit)
      setMinPageLimit(0)
    } else {
      setCurrentPage(currentPage-pageLimit)
    let num = Math.ceil(pageLimit/2) 
    if ((currentPage-(pageLimit+2) < 1)) {
      setMaxPageLimit(pageLimit)
      setMinPageLimit(0)
    } else {
    setMaxPageLimit((currentPage - num))
    setMinPageLimit(((currentPage - pageLimit) - num))
  }}
  }
  const nextDots = () => {
    if (currentPage > (pagesLength - pageLimit)) {
      setCurrentPage(pagesLength)
      setMaxPageLimit(pagesLength)
      setMinPageLimit(pagesLength - pageLimit)
    } else {
      setCurrentPage(currentPage+pageLimit)
    let num = Math.ceil(pageLimit/2)
    if ((currentPage+pageLimit) > (pagesLength - pageLimit)) {
      setMaxPageLimit(pagesLength)
      setMinPageLimit(pagesLength - pageLimit)
    } else {
      setMaxPageLimit(((currentPage+pageLimit) + num)-1)
      setMinPageLimit((currentPage + num)-1)
  }}
  }
  const next = () => {
    if (currentPage !== (pagesLength)) {
      setCurrentPage(currentPage+1)
    }
    let num = Math.ceil(pageLimit/2)
    if (currentPage > num && currentPage < ((pagesLength - num)+1)) {
      setMaxPageLimit(currentPage + num)
      setMinPageLimit((currentPage - pageLimit) + num)
  }
}

  const end = () => {
    setCurrentPage(pagesLength)
    setMaxPageLimit(pagesLength)
    setMinPageLimit(pagesLength-pageLimit)

  }

  let pages = []
  for(let i = 1; i<= Math.ceil(totalPosts/postsPerPage); i++) {
    pages.push(i)
}

  let pagesLength = pages.length

  let pageDecrementButton = null;
  if (currentPage > (pageLimit-2)) {
    pageDecrementButton = <button onClick={prevDots}>...</button>
  }

  let pageIncrementButton = null;
  if (pagesLength > maxPageLimit) {
    pageIncrementButton = <button onClick={nextDots}>...</button>
  }

  const renderList = pages.map((page, index) => {
    if (page < maxPageLimit+1 && page > minPageLimit) {
      return <button key={(index-1)+1} onClick={() => setCurrentPage(page)} className={page === currentPage ? 'active' : ''}>{page}</button>
    } else {
      return null;
    }
  })

  return (
    <div className='pagination'>
      <select className='selector' name='pages' title='pages' onChange={(e) => setPostsPerPage(e.target.value)}>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
      <button key={'start'} onClick={start} disabled={currentPage === pages[0] ? true : false}>{'<<'}</button>
      <button key={'prev'} onClick={prev} disabled={currentPage === pages[0] ? true : false}>{'<'}</button>
      <div className='dots'>{pageDecrementButton}</div>
      <div className='page_nums'>{renderList}</div>
      <div className='dots'>{pageIncrementButton}</div>
      <button key={'next'} onClick={next} disabled={currentPage === pagesLength ? true : false}>{'>'}</button>
      <button key={'end'} onClick={end} disabled={currentPage === pagesLength ? true : false}>{'>>'}</button>
    </div>
  )
}

export default Pagination
