import './App.css';
import logo from '../src/NewStoadzLogo-1.png'
//import DropdownMenu from './DropdownMenu';
import {BrowserRouter as Router, Routes, Route, NavLink} from 'react-router-dom'
//import { Suspense, lazy } from 'react';
//pages
import Home from './pages/Home';
import XToadz from './pages/XToadz';
import XToadzXXL from './pages/XToadzXXL';
import SToadz from './pages/SToadz';
import SBC from './pages/SBC';
import LL from './pages/LL';
import Ribbits from './pages/Ribbits';
import SRibbits from './pages/sRibbits';
//const SRibbits = lazy(() => import('./pages/sRibbits'));


function App() { 
  return (
    <Router>
      <header>
        <nav>
          <NavLink to="/">Search</NavLink>
          <NavLink to="xToadz">xToadz</NavLink>
          <NavLink to="xToadzXXL">xToadzXXL</NavLink>
          <NavLink to="sToadz">sToadz</NavLink>
          <NavLink to="SongbirdCity">Songbird City</NavLink>
          <NavLink to="LuxuryLofts">Luxury Lofts</NavLink>
          <NavLink to="Ribbits">RIBBITS</NavLink>
          <NavLink to="SRibbits">sRIBBITS</NavLink>
        </nav>
      </header>
      <div className='flexbox-container'>
        <div className='flexbox-item logo'>
          <img className='App-logo' src={logo} alt="logo" />
          <h1>XTOADZ RICH LIST</h1>
        </div>
        <main className='flexbox-item list'>
          <Routes>
            <Route path='/' exact element={<Home />}/>
            <Route path='xToadz' exact element={<XToadz />}/>
            <Route path='xToadzXXL' exact element={<XToadzXXL />}/>
            <Route path='sToadz' exact element={<SToadz />}/>
            <Route path='SongbirdCity' exact element={<SBC />}/>
            <Route path='LuxuryLofts' exact element={<LL />}/>
            <Route path='Ribbits' exact element={<Ribbits />}/>
            <Route path='SRibbits' exact element={<SRibbits />}/>
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
