import React from 'react'
import ListItem from './ListItem'
import './App.css'
const List = ({ list, account, type, page, name }) => {
  let holding = type
  let accountName = account
  let keyProp = 'xToadz'
  return (
    <div className='list' key={keyProp}>
        {list.map((value, key) => {
          let number = (key+1)+page
          return (
            <ListItem 
            number={number}
            account={value[accountName]} 
            holding={value[holding].toLocaleString("en-US")}
            type={type}
            keyProp={key}
            name={name}/>
          )
        })}
    </div>
  )
}

export default List
