import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import List from "../List";
import ListHeader from "../ListHeader";
import Pagination from "../Pagination";

function LL() {
  const [LLHolders, setLLHolders] = useState([]);
  const [floor, setFloor] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(25);
  const [lastPostIndex, setLastPostIndex] = useState(0);
  const [firstPostIndex, setFirstPostIndex] = useState(0);
  // eslint-disable-next-line
  const [pageLimit, setPagelimit] = useState(5);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);

  useEffect(() => {
    setLastPostIndex(currentPage * postsPerPage);
    setFirstPostIndex(lastPostIndex - postsPerPage);
    setCurrentPosts(LLHolders.slice(firstPostIndex, lastPostIndex));
  }, [currentPage, postsPerPage, LLHolders, firstPostIndex, lastPostIndex]);

  useEffect(() => {
    axios
      .get("https://richlist-back-end.onrender.com/floor")
      .then((res) => {
        setFloor(res.data.floorDataRes.llFloor);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [floor]);

  useEffect(() => {
    axios
      .get("https://richlist-back-end.onrender.com/LuxuryLofts")
      .then((response) => {
        setLLHolders(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  LLHolders.sort((p1, p2) => (p1.LL < p2.LL ? 1 : p1.LL > p2.LL ? -1 : 0));

  let total = "10k";
  let type = "LL";
  let name = "Luxury Lofts";
  let accountName = "SGBaddress";
  let holders = LLHolders.length;
  let currency = "SGB";

  return (
    <div className="output">
      <ListHeader
        holders={holders}
        total={total}
        floorPrice={floor}
        currency={currency}
      />
      <Pagination
        totalPosts={holders}
        holders={LLHolders}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        maxPageLimit={maxPageLimit}
        setMaxPageLimit={setMaxPageLimit}
        minPageLimit={minPageLimit}
        setMinPageLimit={setMinPageLimit}
      />
      <List
        list={currentPosts}
        account={accountName}
        type={type}
        page={firstPostIndex}
        name={name}
      />
      <Pagination
        totalPosts={holders}
        holders={LLHolders}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        maxPageLimit={maxPageLimit}
        setMaxPageLimit={setMaxPageLimit}
        minPageLimit={minPageLimit}
        setMinPageLimit={setMinPageLimit}
      />
    </div>
  );
}

export default LL;
