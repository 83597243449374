import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import List from "../List";
import ListHeader from "../ListHeader";
import Pagination from "../Pagination";

function XToadzXXL() {
  const [xToadzXXLHolders, setxToadzXXLHolders] = useState([]);
  const [floor, setFloor] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(25);
  const [lastPostIndex, setLastPostIndex] = useState(0);
  const [firstPostIndex, setFirstPostIndex] = useState(0);
  // eslint-disable-next-line
  const [pageLimit, setPagelimit] = useState(5);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);

  useEffect(() => {
    setLastPostIndex(currentPage * postsPerPage);
    setFirstPostIndex(lastPostIndex - postsPerPage);
    setCurrentPosts(xToadzXXLHolders.slice(firstPostIndex, lastPostIndex));
  }, [
    currentPage,
    postsPerPage,
    xToadzXXLHolders,
    firstPostIndex,
    lastPostIndex,
  ]);

  useEffect(() => {
    axios
      .get("https://richlist-back-end.onrender.com/floor")
      .then((res) => {
        setFloor(res.data.floorDataRes.xxlNFTFloor);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [floor]);

  useEffect(() => {
    axios
      .get("https://richlist-back-end.onrender.com/xToadzXXL")
      .then((response) => {
        setxToadzXXLHolders(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  xToadzXXLHolders.sort((p1, p2) =>
    p1.xToadzXXL < p2.xToadzXXL ? 1 : p1.xToadzXXL > p2.xToadzXXL ? -1 : 0
  );

  var total = 0;

  xToadzXXLHolders.forEach((account) => {
    let number = Number(account.xToadzXXL);
    total += number;
  });
  let type = "xToadzXXL";
  let name = "xToadzXXL";
  let accountName = "XRPaddress";
  let holders = xToadzXXLHolders.length;
  let currency = "XRP";

  return (
    <div className="output">
      <ListHeader
        holders={holders}
        total={total}
        floorPrice={floor}
        currency={currency}
      />
      <Pagination
        totalPosts={holders}
        holders={xToadzXXLHolders}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        maxPageLimit={maxPageLimit}
        setMaxPageLimit={setMaxPageLimit}
        minPageLimit={minPageLimit}
        setMinPageLimit={setMinPageLimit}
      />
      <List
        list={currentPosts}
        account={accountName}
        type={type}
        page={firstPostIndex}
        name={name}
      />
      <Pagination
        totalPosts={holders}
        holders={xToadzXXLHolders}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        maxPageLimit={maxPageLimit}
        setMaxPageLimit={setMaxPageLimit}
        minPageLimit={minPageLimit}
        setMinPageLimit={setMinPageLimit}
      />
    </div>
  );
}

export default XToadzXXL;
