import React from 'react'

const ListItem = ({ number, account, holding, type, keyProp, name}) => {
  var hold = ''
  if (type === 'sRibbits') {
    if (holding.length <= 24) {
      hold = '< 1'
    } else {
    hold = holding.slice(0,-24)
    }
  } else {
    hold = holding
  }
  return (
    <div className='card'  key={keyProp}>
        <div className='card_info'>
            <p>{number}<br></br>
            {account}<br></br>
            {name}: {hold}</p>
        </div>
    </div>
  )
}

export default ListItem