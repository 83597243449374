import React from 'react'

const ListHeader = ({holders, total, floorPrice, currency}) => {
  return (
    <div className='list_header'>
      <div className='holders'>
        Total Holders:<br></br> {holders}
      </div>
      <div className='total'>
        Total Minted:<br></br> {total}
      </div>
      <div className='floor_price'>
        Floor Price:<br></br> {floorPrice} {currency}
      </div>
    </div>
  )
}

export default ListHeader
