import React from 'react'
import '../App.css'
import axios from 'axios';
import { useEffect, useState } from "react"


function Home() {
  const [xToadz, setxToadz] = useState('');
  const [xToadzXXL, setxToadzXXL] = useState('');
  const [Ribbits, setRibbits] = useState('');
  const [sToadz, setsToadz] = useState('');
  const [SBC, setSBC] = useState('');
  const [LL, setLL] = useState('');
  const [sRibbits, setsRibbits] = useState('');
  const [searchS, setSearchS] = useState(false);
  const [searchX, setSearchX] = useState(false);
  const [notfound, setNotFound] = useState(false);

  //const [search, setsearch] = useState('rp6cHHhgbiYPFzVrnwj7xaG21crvg83hse')
  const [search, setSearch] = useState('')
  //0xf91422115e68c0a9f35904c018fcf648a1e53d12')
  //0x9d55939f0BC1C119A6611cD39D5582b80c239D59

   useEffect(() => {
  //     axios
  //       .get("https://api.xrpldata.com/api/v1/xls20-nfts/stats/issuer/rBwoDxDHHRkVq7jtTcjD9YG1TUFvpPvGXx/taxon/1")
  //       .then((response) => {
  //         console.log(response.data.data.collection_info.floor[0].amount)
  //         setxToadzFloor(response.data.data.collection_info.floor[0].amount)
  //       })
  //     axios
  //       .get('https://api.xrpldata.com/api/v1/xls20-nfts/stats/issuer/rBwoDxDHHRkVq7jtTcjD9YG1TUFvpPvGXx/taxon/2')
  //       .then((res) => {
  //         setxToadzXXLFloor(res.data.data.collection_info.floor[0].amount)
  //       })
  //     }, [])

        axios
          .get("https://richlist-back-end.onrender.com/xToadz")
          .then((response) => {
            let xToadzres = response.data
            xToadzres.forEach(account => {
              if (account.XRPaddress === search) {
                setxToadz(account.xToadz)
              }
            });
          }).catch((err) => {console.log(err)});
        axios
          .get("https://richlist-back-end.onrender.com/xToadzXXL")
          .then((response) => {
            let xToadzXXLres = response.data
            xToadzXXLres.forEach(account => {
              if (account.XRPaddress === search) {
                setxToadzXXL(account.xToadzXXL)
              }
            })
          }).catch((err) => {console.log(err)});
        axios
          .get("https://richlist-back-end.onrender.com/Ribbits")
          .then((response) => {
            let RibbitsRes = response.data
            RibbitsRes.forEach(account => {
              if (account.XRPaddress === search) {
                setRibbits(parseInt(account.Ribbits).toLocaleString("en-US"))
              }
      })
    }).catch((err) => {console.log(err)});
   
    axios
    .get("https://richlist-back-end.onrender.com/sToadz")
    .then((response) => {
      let sToadzres = response.data
      sToadzres.forEach(account => {
        if (account.SGBaddress === search.toLowerCase()) {
          setsToadz(account.sToadz)
        }
      });
    }).catch((err) => {console.log(err)});
  axios
    .get("https://richlist-back-end.onrender.com/SongbirdCity")
    .then((response) => {
      let SBCres = response.data
      SBCres.forEach(account => {
        if (account.SGBaddress === search.toLowerCase()) {
          setSBC(account.SBC)
        }
      })
    }).catch((err) => {console.log(err)});
  axios
    .get("https://richlist-back-end.onrender.com/LuxuryLofts")
    .then((response) => {
      let LLres = response.data
      LLres.forEach(account => {
        if (account.SGBaddress === search.toLowerCase()) {
          setLL(account.LL)
        }
})
}).catch((err) => {console.log(err)});


axios
.get("https://richlist-back-end.onrender.com/sRibbits")
.then((response) => {
  let sRibbitsRes = response.data
  sRibbitsRes.forEach(account => {
    if (account.SGBaddress === search.toLowerCase()) {
      let sNum = (account.sRibbits)
      let sRibbits = parseInt(sNum.slice(0 , -18))
      setsRibbits(sRibbits.toLocaleString("en-US"))
    }
})
}).catch((err) => {console.log(err)});
}, [search])

  const searchButton = () => {
    setSearchS(false)
    setSearchX(false)
    setNotFound(false)
    if (search[0] === '0') {
      setSearchS(true)
  } else if (search[0] === 'r') {
      setSearchX(true)
  } else {
    setNotFound(true)
  }
  }

  return (
    <div className='search-box'>
      <input className='search' placeholder='Search wallet address' autoComplete='false' id='search' onChange={(e) => setSearch(e.target.value)}></input>
      <br></br>
      <button className='button' onClick={searchButton}>Search</button>
      {searchX === true
      ? <section className='xSearch'>
        <h1 className='xToadz'>xToadz: {xToadz}</h1>
        <h1 className='xToadzXXL'>xToadzXXL: {xToadzXXL}</h1>
        <h1 className='Ribbits'>RIBBITS: {Ribbits}</h1>
      </section>
      :''
}
      {searchS === true
      ? <section className='sSearch'>
        <h1 className='sToadz'>sToadz: {sToadz}</h1>
        <h1 className='SBC'>Songbird City: {SBC}</h1>
        <h1 className='LL'>Luxury Lofts: {LL}</h1>
        <h1 className='sRibbits'>sRIBBITS: {sRibbits}</h1>
      </section>
      : ''
}
    {notfound === true
    ? <div className='notFound'>
      Wallet address not found.<br></br>
      Please try again
    </div>
    : ''
}
    </div>
  )
}

export default Home


// <h1 className='xToadzFloor'>xToadz floor price:          {xToadzFloor}</h1>
// <h1 className='xToadzXXLFloor'>xToadzXXL floor price:       {xToadzXXLFloor}</h1>